
import { getShopInfo } from '@/api/shop'
import { Component, Vue } from "vue-property-decorator";
import { $auth, AuthRole } from "@/pages/auth/services/auth.service";
import { getDomainByBasicInformation } from "@/api/account"
const bgimg = require('../assets/bg-3.png')
@Component({
  name: "login-page"
})
export default class LoginPage extends Vue {
  urlData = '';
  codeImageUrl = '';
  roles = Object.values($auth.roles);
  currentRole: AuthRole = $auth.buildRole();
  form = $auth.form;
  mobileForm = $auth.mobileForm;
  loading = false;
  isMounted = false;
  activeName = 'account';
  smsSuffixText = '获取验证码';
  smsTimeout = 0;
  smsCount = 0;
  bgimg = bgimg;
  showSubdomains = true; // 是否显示切换为
  zzrzVisible = this.currentRole.zzrzVisible;
  get pwdCover() {
    return new Array(this.form.pwd.length).fill('●').join('');
  }

  mounted() {
    if (location.host.indexOf('192.168') === -1 && location.host.indexOf('sshlqf') === -1) {
      const oldUrlList = location.host.split(".");
      const endUrl =
        oldUrlList[oldUrlList.length - 2] +
        "." +
        oldUrlList[oldUrlList.length - 1]; // 获取当前地址栏后缀主域名地址
      getDomainByBasicInformation(endUrl).then(res => {
        this.urlData = res.text;
        document.title = res.name;
        this.handleIcoCreate(res.logo)
      })
    } else {
      this.urlData = '山南水北（北京）生态科技发展有限公司 | 京ICP备2020039568号-1';
      document.title = '山水互联';
      const imgUrl = 'https://sshlwap.oss-cn-beijing.aliyuncs.com/files/normal/56690F1AB073464CB6604A0BCE8E2758.png'
      this.handleIcoCreate(imgUrl)
    }
    this.refreshCode();
    // 是否是二级域名
    const subdomains = window.location.host;
    this.showSubdomains = subdomains.split('.').length === 3; // 是否显示切换为
    if (!this.showSubdomains) { // 是二级域名
      this.getShopInfo()
    } else this.isMounted = true

    // setTimeout(() => {
    //   this.isMounted = true;
    // }, 500);
  }

  // 关闭资质认证弹窗
  closeZzrz() {
    this.zzrzVisible.isshow = false;
  }

  handleIcoCreate(icoUrl) {
    const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    console.log(link);
    link.href = icoUrl;
  }

  async clickSmsSuffixText() {
    let is: any
    await (this.$refs.mobileFormRef as any).validateField('mobile', (valid: any) => void (is = valid))

    if (this.smsTimeout || is) return
    const { mobile, captcha } = this.mobileForm
    if (!/^1[3456789]\d{9}$/.test(mobile)) return
    // if (!this.showSubdomains) await $auth.smscodeDropShipping(mobile, captcha || undefined)
    if (!this.showSubdomains) await $auth.smscodeDropShipping(mobile, undefined)
    else await $auth.publicSmscodeDropShipping(mobile, captcha || undefined)

    let timer: any = null
    this.smsTimeout = 60
    this.smsSuffixText = '60秒'
    timer = setInterval(() => {
      this.smsTimeout--;
      if (this.smsTimeout <= 0) {
        this.smsSuffixText = '获取验证码';
        this.smsTimeout = 0;
        clearInterval(timer);
      } else {
        this.smsSuffixText = this.smsTimeout + '秒';
      }
    }, 1000);
  }

  async getShopInfo() {
    const subdomains = window.location.host;
    try {
      const res = await getShopInfo(subdomains)
      if (res.background_img) { // 如果设置了个性化背景图片 隐藏默认背景图
        this.isMounted = false;
        this.bgimg = res.background_img;
      } else this.isMounted = true;
    } catch (error) {
      this.isMounted = true;
    }
  }

  handleClick(tab, event) {
    console.log(tab, event);
  }

  refreshCode() {
    this.codeImageUrl = this.currentRole.getCodeImageUrl('LOGIN');
    this.$forceUpdate();
  }

  switchRole(role) {
    this.currentRole = $auth.switchRole(role);
    $auth.clearForm();
    this.refreshCode();
  }

  removeAccount(ref, value: string) {
    this.currentRole.removeAccount(value);
    ref.debouncedGetData(ref.value);
  }

  handleSelectAccount(account) {
    $auth.selectAccount(account.value, account.pwd);
    this.$forceUpdate();
  }

  async login(formRef) {
    try {
      const valid = await formRef.validate();
      console.log(valid, this.activeName);

      if (!valid) return;
      this.loading = true;
      try {
        if (this.activeName === 'account') {
          console.log(666666);

          const res = await $auth.login();
          console.log(res, 'login');
          this.loading = false;
          const message = '抱歉，由于您的商城暂未完成资质认证商城管理功能暂未开启，请联系您的商城超级管理员进行处理!'
          if (!res) {
            this.$store.dispatch("logoutAction")
            await this.$confirm(message, '提示', { type: 'success', showCancelButton: false, showConfirmButton: false })
          }
        } else if (this.activeName === 'mobile') {
          try {
            await $auth.loginDropShipping();
          } catch (error) {
            this.refreshCode();
            this.smsCount++
          }
        }
      } catch (error) {
        this.refreshCode();
      } finally {
        this.loading = false;
      }
    } catch (error) {

    }
  }
}
